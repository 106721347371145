.custom1 {
  @media #{$lg-layout} {
    width: 100% !important;
    height: auto !important;
    min-height: 185px !important;
  }

  @media #{$large-mobile} {
    width: 100% !important;
    height: auto !important;
    min-height: 230px !important;
  }

  @media #{$small-mobile} {
    width: 100% !important;
    height: auto !important;
    min-height: 180px !important;
  }

  @media #{$extra-device} {
    width: 100% !important;
    height: auto !important;
    min-height: 250px !important;
  }

  @media #{$laptop-device} {
    width: 100% !important;
    height: auto !important;
    min-height: 210px !important;
  }

  @media #{$smlg-device} {
    width: 100% !important;
    height: auto !important;
    min-height: 240px !important;
  }
}

.justifypara {
  text-align: justify;
  text-justify: inter-word;
}

.footer-left1 {
  background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
  padding: 150px 120px;
  position: relative;
  z-index: 2;
  margin-right: -15px;
  border-top-right-radius: 6px;

  @media #{$lg-layout} {
    padding: 120px 80px;
  }

  @media #{$md-layout} {
    padding: 60px 70px;
  }

  @media #{$sm-layout} {
    padding: 60px 30px;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url("/assets/images/pattern-1.png");
    content: "";
    z-index: 1;
    opacity: 0.5;
    border-top-right-radius: 6px;
  }

  .inner {
    z-index: 5;
    position: relative;

    &::after {
      //content: "Noorisys";
      content: url(/assets/images/logo/big-logo.png);
      position: absolute;
      right: 0;
      top: 50%;
      z-index: -1;
      font-size: 240px;
      font-weight: 900;
      color: rgba(0, 0, 0, 0.1);
      transform: translateY(-50%);
      width: 295px;
      height: 301px;
    }

    > span {
      color: #fff;
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 4px;
      display: block;
      margin-bottom: 9px;

      @media #{$large-mobile} {
        letter-spacing: 1px;
        margin-bottom: 9px;
      }
    }

    h2 {
      color: #ffffff;
      font-size: 75px;
      font-weight: 900;
      font-family: "Montserrat", sans-serif;
      line-height: 90px;

      @media #{$lg-layout} {
        font-size: 50px;
        line-height: 61px;
      }

      @media #{$sm-layout} {
        font-size: 45px;
        line-height: 51px;
      }
    }

    a {
      &.rn-button-style--2 {
        margin-top: 30px;
        border-color: #ffffff;
        color: #ffffff;

        @media #{$sm-layout} {
          margin-top: 10px;
          padding: 10px 23px;
        }

        @media #{$large-mobile} {
          padding: 7px 16px;
        }

        &:hover {
          background: #fff;
          border-color: #fff;
          color: $theme-color;
        }
      }
    }
  }
}

.footer-right1 {
  button {
    &.video-popup {
      &.position-top-center {
        position: absolute;
        top: 50%;
        left: 50%;
        border: 0 none;
        width: 110px;
        height: 110px;
        margin-left: -55px;
        margin-top: -55px;
        transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);

        @media #{$sm-layout} {
          width: 70px;
          height: 70px;
          margin-left: -35px;
          margin-top: -35px;
        }

        &:hover {
          transform: scale3d(1.15, 1.15, 1.15);
        }

        &.md-size {
          width: 80px;
          height: 80px;
          margin-left: -40px;
          margin-top: -40px;
        }
      }

      &.theme-color {
        background: $theme-color;
        // background: $theme-color;
        border-color: $theme-color;

        &::before {
          border-left: 15px solid #ffffff;
        }
      }

      &.black-color {
        background: #000000;
        border-color: #000000;

        &::before {
          border-left: 15px solid #ffffff;
        }
      }
    }
  }

  background-image: url(/assets/images/bg/bg-image-8.jpg);
  padding: 200px 70px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-left: 120px;

  @media #{$lg-layout} {
    padding: 119px 21px;
    padding-left: 70px;
  }

  @media #{$md-layout} {
    padding: 60px 70px;
  }

  @media #{$sm-layout} {
    padding: 60px 30px;
  }

  .footer-link {
    h4 {
      color: #c6c9d8;
      font-weight: 500;
      margin-bottom: 30px;
      opacity: 0.75;

      @media #{$sm-layout} {
        margin-bottom: 14px;
      }
    }

    ul {
      &.ft-link {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          a {
            color: #c6c9d8;
            font-size: 16px;
            @extend %transition;
            opacity: 0.75;
          }

          &:hover {
            a {
              color: $theme-color;
            }
          }

          & + li {
            margin-top: 13px;

            @media #{$sm-layout} {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

.rn-finding-us-area-custom {
  max-height: 800px;

  .inner {
    position: relative;
    padding-left: 240px;
    padding-top: 110px;
    padding-bottom: 110px;

    @media #{$lg-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    @media #{$md-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    @media #{$sm-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    .content-wrapper {
      .content {
        background: #ffffff;
        padding: 75px 46px;
        // padding: 75px 100px;
        position: relative;
        z-index: 2;
        width: 540px;
        border-radius: 10px;
        height: 440px;
        // border-color: #000000;
        // border: 1px solid;

        @media #{$lg-layout} {
          padding: 60px 50px;
          width: 100%;
          border-radius: 0;
        }

        @media #{$md-layout} {
          padding: 60px 50px;
          width: 100%;
          border-radius: 0;
        }

        @media #{$sm-layout} {
          padding: 30px 30px;
          width: 100%;
          border-radius: 0;
        }

        h4 {
          color: #000000;
          font-size: 36px;
          font-weight: 500;
          margin-bottom: 20px;

          @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 12px;
          }
        }

        p {
          font-size: 18px;
          color: #000000;
          font-weight: 300;
          margin-bottom: 41px;
          padding-right: 10%;

          @media #{$sm-layout} {
            margin-bottom: 24px;
            padding-right: 0;
          }
        }
      }
    }

    .thumbnail {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;

      @media #{$lg-layout} {
        position: static;
        height: 100%;
      }

      @media #{$md-layout} {
        position: static;
        height: 100%;
      }

      @media #{$sm-layout} {
        position: static;
        height: 100%;
      }

      img {
        border-radius: 10px;

        @media #{$lg-layout} {
          border-radius: 0;
        }

        @media #{$md-layout} {
          border-radius: 0;
        }

        @media #{$sm-layout} {
          border-radius: 0;
        }
      }

      button {
        &.video-popup {
          &.position-top-center {
            position: absolute;
            top: 50%;
            left: 50%;
            border: 0 none;
            width: 110px;
            height: 110px;
            margin-left: -55px;
            margin-top: -55px;
            transition: all 1s cubic-bezier(0, 0, 0.2, 1);
            box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);

            @media #{$sm-layout} {
              width: 70px;
              height: 70px;
              margin-left: -35px;
              margin-top: -35px;
              top: 22%;
            }

            @media #{$lg-layout} {
              top: 30%;
            }

            @media #{$md-layout} {
              top: 30%;
            }

            @media #{$laptop-device} {
              left: 70%;
            }

            @media #{$small-mobile} {
              top: 20%;
            }

            &:hover {
              transform: scale3d(1.15, 1.15, 1.15);
            }

            &.md-size {
              width: 80px;
              height: 80px;
              margin-left: -40px;
              margin-top: -40px;
            }
          }

          &.theme-color {
            background: $theme-color;
            // background: $theme-color;
            border-color: $theme-color;

            &::before {
              border-left: 15px solid #ffffff;
            }
          }

          &.black-color {
            background: #000000;
            border-color: #000000;

            &::before {
              border-left: 15px solid #ffffff;
            }
          }
        }
      }
    }
  }
}

ul {
  &.nav1 {
    list-style: none;
    position: relative;
    padding: 0;
    margin: 0;
    display: inline-flex;
    justify-content: left;
    flex-wrap: nowrap;
    word-wrap: normal;
    max-width: auto;
    margin: 0px auto;
    margin-bottom: 5px;
    outline: none;

    @media #{$large-mobile} {
      margin-bottom: -10px;
    }

    li {
      padding-left: 0px;
      padding-right: 0px;
      flex-basis: 20%;
      // margin-bottom: 30px;
      outline: none;
      width: 100px;
      // word-wrap:nowrap;
      cursor: pointer;

      @media #{$sm-layout} {
        flex-basis: 20%;
        margin-bottom: 14px;
        padding-left: 7px;
        padding-right: 7px;
      }

      @media #{$large-mobile} {
        flex-basis: 25%;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 10px;
      }

      a {
        &.nav-link {
          position: relative;
          display: inline-block;
          width: 100%;

          img {
            @extend %transition;
            border-radius: 6px;
            width: 100%;

            &:hover {
              transform: scale(1.1);
              object-fit: contain;
              box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
            }
          }

          &::after {
            width: 13px;
            height: 13px;
            background: $theme-color;
            right: -8.5px;
            top: -8.5px;
            position: absolute;
            content: "";
            border-radius: 100%;
            @extend %transition;
            opacity: 0;
            transform: scale(0);
          }
        }
      }

      &.react-tabs__tab--selected {
        .thumb {
          &::after {
            opacity: 1;
            transform: scale(1);
          }

          img {
            transform: scale(1.1);
            object-fit: contain;
            border-radius: 6px;
            box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}

a,
button {
  &.down-btn {
    padding: 0 23px;
    height: 40px;
    display: inline-block;
    line-height: 34px;
    border: 2px solid #fff;
    border-radius: 4px;
    font-size: 14px;
    position: relative;
    z-index: 2;
    color: #fff;
    letter-spacing: 0.2px;
    @extend %transition;
    text-transform: uppercase;

    &:hover {
      border: 2px solid $theme-color;
      transform: translateY(-3px);
      color: #ffffff;
      background: $theme-color;
    }

    @media #{$sm-layout} {
      padding: 0 10px;
      font-size: 10px;
    }
  }
}

.custom_download_app {
  background-color: #0fc1a7;
}

.custom_qr_code {
  height: 200px;
  width: auto;
}

.custom-justify-small {
  @media (max-width: 370px) {
    justify-content: center !important;
    padding-right: 20px;
  }
}

.custom-coursier-color {
  background-image: linear-gradient(to right, #2f64af, #53bfd0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-primary-care-color {
  color: #298d9c;
}

.custom-annachi-color {
  color: #83b735;
}

.custom-secondary-color {
  color: #1d237a;
}

.custom-scholarship-color {
  color: #ffd939;
}

.custom-courier-color {
  color: #012296;
}

.custom-healthcare-color {
  color: #1296cc;
}

.custom-tourism-color {
  color: #00aeff;
}

.custom-hotel-booking-color {
  color: #00722e;
}

.custom-fasterchecks-color {
  color: #ff5500;
}

.custom-digital-solution-color {
  color: #0096ff;
}

.custom-nursing-color {
  color: #00dddd;
}

.custom-multilingual-color {
  color: #ffad01;
}

.custom-portery-color {
  color: #126075;
}

.custom-online-book-color {
  color: #2196f3;
}

.custom-pharmacist-color {
  color: #1396ce;
}

.custom-food-color {
  color: #580300;
}

.custom-online-shop-color {
  color: #233e99;
}

.custom-delivery-management-color {
  color: #5db35c;
}

.custom-hajj-color {
  color: #1f6421;
}

.custom-hide-color {
  color: #5aadbf;
}

.custom-researcher-color {
  color: #012296;
}

.custom-government-color {
  color: #27a3cb;
}

.custom-elearning-color {
  color: #021e36;
}

.custom-dietapp-color {
  color: #e55a37;
}

.custom-doctor-appointment-color {
  color: #005d93;
}

.custom-erp-color {
  color: #f55531;
}

.custom-event-color {
  color: #fe679a;
}

.custom-food-delivery-color {
  color: #710100;
}

.custom-school-color {
  color: #faae1a;
}

.custom-memories-color {
  color: #00c0d1;
}

.custom-online-travel-color {
  color: #f9b433;
}

.custom-powerloom-color {
  color: #99b4d1;
}

.custom-service-center-color {
  color: #b71a25;
}

.custom-social-app-color {
  color: #003c30;
}

.custom-vehicle-color {
  color: #020202;
}

.custom-survey-color {
  color: #c33649;
}

.custom-choice-making-color {
  color: #2b3a8f;
}

.custom-clinic-presentation-color {
  color: #1a6427;
}

.custom-online-laundary-color {
  color: #013277;
}

.custom-car-space {
  color: #3bdfbd;
}

.custom-online-kirana {
  color: #06051e;
}

.custom-influencer-color {
  color: #db5e70;
}

.custom-social-color {
  color: #774eb3;
}

.custom-social-platform-color {
  color: #863cb2;
}

.custom-school-management-product {
  color: #aa0f1b;
}

.custom-service-market-product {
  color: #9700ff;
}

.custom-food-delivery-product {
  color: #e96e00;
}

.custom-match-making-product {
  color: #ad987e;
}

.custom-study-matching-portfolio {
  color: #f07317;
}

.custom-queue-management-portfolio {
  color: #5270ef;
}

.custom-home-cleaning-portfolio {
  color: #a48a43;
}

.custom-ssg-portfolio {
  color: #3b2064;
}

.custom-social-media-platform-portfolio {
  color: #dd377c;
}

.custom-real-state-portfolio {
  color: #080e41;
}

.custom-social-media-marketing-portfolio {
  color: #007bfe;
}

.custom-transport-management-product {
  color: #984065;
}

.custom-helpdesk-product {
  color: #3164f4;
}
.custom-fitness-app-product {
  color: #e64c1a;
}
.custom-5g-blog {
  color: #8bc34a;
}
.custom-water-donation-color {
  color: #245456;
}
.custom-event-expert-color {
  color: #808a6d;
}

.custom-collection-portfolio {
  background: linear-gradient(
    90deg,
    rgba(121, 121, 121, 1) 21%,
    rgba(217, 217, 217, 1) 96%
  );
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  color: transparent;
}

.custom-mugenzi-portfolio {
  background: linear-gradient(
    90deg,
    rgba(67, 207, 3, 1) 0%,
    rgba(228, 255, 25, 1) 100%
  );
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  color: transparent;
}

.custom-matness-portfolio {
  background: linear-gradient(
    90deg,
    rgba(67, 207, 3, 1) 0%,
    rgba(228, 255, 25, 1) 100%
  );
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  color: transparent;
}

.custom-digital-business-cards-portfolio {
  background: linear-gradient(
    90deg,
    rgba(238, 4, 166, 1) 9%,
    rgba(86, 20, 130, 1) 78%
  );
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  color: transparent;
}

.custom-grocery-management {
  background: linear-gradient(90deg, #b40681 9%, rgb(255, 145, 0) 78%);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  color: transparent;
}

.custom-hospital-management {
  background: linear-gradient(90deg, rgb(250, 74, 5) 9%, rgb(255, 145, 0) 78%);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  color: transparent;
}

.custom-ndashaka-portfolio {
  background: linear-gradient(
    0deg,
    rgba(203, 20, 116, 1) 0%,
    rgba(0, 150, 253, 1) 100%
  );
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  color: transparent;
}

ul {
  &.custom-social-hover {
    li {
      a {
        &:hover {
          background-color: #2f64af !important;
          border-color: #2f64af !important;
        }
      }
    }
  }
}
