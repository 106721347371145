:root {
    --primary: #825af5;
    /* --white: #000000; */
    --white: #fff;
    --text-light: #dadfe2;
    --text-dark: #0b1944;
    --app-color: #48b101;
}

/* .primary-care{
    background-color: #677294;
} */
.primary_app_deliverables {
    padding: 80px 0px;
    position: relative;
}

.primary-care .primary_app_deliverables .container-fluid {
    padding-left: 87px;
}

.primary_app_deliverables .primary_app_deliverables_content {
    position: relative;
    width: 100%;
    top: 0;
    bottom: 0;
    height: fit-content;
    margin: auto 0;
}

/* .deliverables_carousel {
    width: fit-content;
    margin: 0 auto;
} */

/* .primary_app_deliverables .primary_app_deliverables_content h2 {
    font-size: 24px;
    padding-bottom: 7px;
}

.primary_app_deliverables .primary_app_deliverables_content p {
    margin: 0;
    font-size: 13px;
    line-height: normal;
} */

.primary_app_deliverables .deliverables_item .deliverables_carousel .owl-dots {
    display: none;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .owl-stage {
    display: flex;
    align-items: flex-end;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .owl-stage .owl-item {
    min-width: fit-content;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item .item_inner {
    position: relative;
    padding: 0 20px 20px 20px;
    overflow: hidden;
    min-width: 155px;
    max-width: 155px;
    margin: 0 auto;
}

.deliverables_item .deliverables_carousel .item .item_inner .item_bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 20px);
    background: linear-gradient(to right, #2f64af, #53bfd0);
    /* background: #76d13b; */
    z-index: -1;
    border-radius: 7px;
}

.deliverables_item .deliverables_carousel .item .item_inner .coursier {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 20px);
    background: linear-gradient(to right, #2f64af, #53bfd0);
    /* background: #76d13b; */
    z-index: -1;
    border-radius: 7px;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item .item_inner .coursier img {
    position: absolute;
    right: -30px;
    width: 100px;
    bottom: -15px;
    opacity: 0.3 !important;
}

.deliverables_item .deliverables_carousel .item .item_inner .primary-care {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 20px);
    /* background: linear-gradient(to right, #2f64af, #53bfd0); */
    background: #298d9c;
    z-index: -1;
    border-radius: 7px;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item .item_inner .primary-care img {
    position: absolute;
    right: -30px;
    width: 100px;
    bottom: -15px;
    opacity: 0.3 !important;
}



.primary_app_deliverables .owl-stage {
    margin: 0 auto;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item .item_inner .item_bg img {
    position: absolute;
    right: -30px;
    width: 100px;
    bottom: -15px;
    opacity: 0.3 !important;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item .app_icon img {
    width: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item .app_icon {
    background: #f8f9fd;
    overflow: hidden;
    border-radius: 7px;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 17px;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item .app_icon img {
    max-width: 50px;
    display: block;
    margin: auto;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item h3 {
    margin: 0;
    color: var(--white);
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 4px;
    white-space: nowrap;
}

.primary_app_deliverables .deliverables_item .deliverables_carousel .item h4 {
    margin: 0;
    color: var(--white);
    font-weight: 300;
    /* text-align: center; */
    font-size: 12px;
    opacity: 0.7;
}

.primary_app_deliverables_content .hosting_title h2 {
    margin: 0 !important;
}

.primary-care .get_started_area .developer_product_content .develor_tab .nav-item .nav-link {
    opacity: 1 !important;
}

.primary-care .get_started_area .tab_img_info img {
    max-width: 100% !important;
    max-height: 450px !important;
}

/* .primary_app_deliverables::before,
.primary_app_deliverables::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(55 120 11) 98%);
    z-index: 1;
}

.primary_app_deliverables::before {
    display: block;
    right: unset;
    left: 0;
    transform: rotate(180deg);
    z-index: 2;
} */

.deliverables_item .slider_btn {
    position: absolute;
    top: 24px;
    bottom: 0;
    height: fit-content;
    margin: auto 0;
    left: -60px;
    padding: 0;
    background: none;
    z-index: 99;
    cursor: pointer;
    transition: all 0.4s;
}

.deliverables_item .slider_btn.PreviousBtn {
    left: unset;
    right: 31px;
}

.deliverables_item .slider_btn a {
    width: 35px;
    height: 35px;
    background: #ffffff52;
    border-radius: 50%;
    position: relative;
    display: block;
    transition: all 0.5s;
    margin: 0px 8px;
}

.deliverables_item .slider_btn a i {
    text-align: center;
    width: 100%;
    line-height: 35px;
    color: var(--white);
    font-size: 21px;
    font-weight: 300;
    opacity: 0.8;
}

.deliverables_item .slider_btn:hover a {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.deliverables_carousel.owl-carousel .owl-next,
.deliverables_carousel.owl-carousel .owl-prev {
    position: absolute;
    top: 86px;
    left: 11px;
    margin: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 1.5rem !important;
    color: var(--white) !important;
    background: rgb(241 241 241 / 42%) !important;
    backdrop-filter: blur(3px);
    opacity: 1;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.deliverables_carousel.owl-carousel .owl-next .nav-btn,
.deliverables_carousel.owl-carousel .owl-prev .nav-btn {
    display: block;
    margin: auto;
    height: 35px;
    overflow: hidden;
}

.deliverables_carousel.owl-carousel .owl-next .nav-btn i,
.deliverables_carousel.owl-carousel .owl-prev .nav-btn i {
    text-align: center;
    width: 100%;
    line-height: 35px;
    color: var(--white);
    font-size: 21px;
    font-weight: 300;
    opacity: 0.8;
}

.deliverables_carousel.owl-carousel .owl-next {
    right: 11px;
    left: unset;
}

.deliverables_carousel.owl-carousel .owl-next.disabled,
.deliverables_carousel.owl-carousel .owl-prev.disabled {
    display: none !important;
}

.platform_used .intregration_item {
    margin-bottom: 8px;
}

.platform_used .intregration_item :hover {
    background-color: fff;
    transform: translateX(1px);
}

.platform_used .intregration_item .media .media-body h5 {
    margin: 0;
    font-size: 13px;
    padding-bottom: 3px;
    color: #677294;
}

.platform_used .intregration_item .media .media-body p {
    color: var(--text-dark);
    margin: 0;
    font-weight: 500;
    font-size: 16px;
}

.platform_used .intregration_icon {
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0px 10px 46px -29px rgb(29 7 90 / 25%);
}

.platform_used .intregration_icon img {
    width: 45px;
    min-width: 45px;
    margin-right: 30px;
}