.custom-modal{
    background-color: rgba(0,0,0,0.1);
    .ant-btn {
        background-color: $theme-color;
        border-color: $theme-color !important;
        color:#ffffff;
        &:hover{
            transform: scale3d(1.15, 1.15, 1.15);
            background-color: #ffff;
            color:$theme-color;
            border-color:$theme-color;
        }
      }    
      p {
        @extend %transition;
        color: rgba(29,29,36,0.75);
        @media #{$large-mobile} {
            color:rgba(29,29,36,0.75);
        }
    &:link{
      color: #1677ff;
    }

    }
}
.cookie-consent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: #fff;
    padding: 16px;
    width: 100%;
  }
  
  .cookie-consent-button {
    background-color: #f9004d;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    margin-left: 16px;
  }
  