.rn-about-us-area {
    .inner {
        position: relative;
        // padding-left: 240px;
        padding-top: 110px;
        padding-bottom: 110px;

        @media #{$lg-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$md-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$sm-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        .content-wrapper {
            .content {
                background: #272527;
                padding: 120px 100px;
                position: relative;
                z-index: 2;
                width: 800px;
                border-radius: 10px;

                @media #{$lg-layout} {
                    padding: 60px 50px;
                    width: 100%;
                    border-radius: 0;
                }

                @media #{$md-layout} {
                    padding: 60px 50px;
                    width: 100%;
                    border-radius: 0;
                }

                @media #{$sm-layout} {
                    padding: 30px 30px;
                    width: 100%;
                    border-radius: 0;
                }

                h4 {
                    color: #ffffff;
                    font-size: 36px;
                    font-weight: 500;
                    margin-bottom: 20px;

                    @media #{$sm-layout} {
                        font-size: 26px;
                        margin-bottom: 12px;
                    }
                }

                p {
                    font-size: 18px;
                    color: #ffffff;
                    font-weight: 300;
                    margin-bottom: 41px;
                    padding-right: 10%;

                    @media #{$sm-layout} {
                        margin-bottom: 24px;
                        padding-right: 0;
                    }
                }
            }
        }

        .thumbnail {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;

            @media #{$lg-layout} {
                position: static;
                height: 100%;
            }

            @media #{$md-layout} {
                position: static;
                height: 100%;
            }

            @media #{$sm-layout} {
                position: static;
                height: 100%;
            }

            img {
                border-radius: 10px;

                @media #{$lg-layout} {
                    border-radius: 0;
                }

                @media #{$md-layout} {
                    border-radius: 0;
                }

                @media #{$sm-layout} {
                    border-radius: 0;
                }
            }
        }
    }
}