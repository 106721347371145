.review {
    padding: 30px;

    .card {
        border-radius: 20px;
        box-shadow: 1px 30px 15px -10px rgba(0, 0, 0, 0.2);
        min-height: 400px;
        transition: transform 0.3s;

        .img {
            border-radius: 50%;
            height: 80px;
            width: 80px;
            position: absolute;
            top: -25px;
            border: 2px solid #f0f0f0;
        }

        h6 {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .card:hover {
        transform: scale(1.08) translateZ(0);
        cursor: pointer;
    }
}


.interactive {
    .interactive-title {
        @media #{$sm-layout} {
            font-size: 40px;
        }
    }
}