button,
a {
  &.get-a-quote {
    color: #c6c9d8;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 2px solid #b1b4c1;
    padding: 15px 40px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 600;
    transition: 0.3s;
    @media #{$md-layout} {
      font-size: 18px;
    }
    @media #{$sm-layout} {
      padding: 9px 25px;
      font-size: 16px;
    }
    &.btn-solid {
      border: 2px solid $theme-color;
      background: $theme-color;
      color: #ffffff;
    }
    &.btn-food {
      border: 2px solid #e96e00;
      background: #e96e00;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-fitness {
      border: 2px solid #fc5b27;
      background: #fc5b27;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-umrah {
      border: 2px solid #00aeff;
      background: #00aeff;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-taxi-subscription {
      border: 2px solid #f2c326;
      background: #f2c326;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-lms {
      border: 2px solid white;
      background: linear-gradient(
        90deg,
        rgba(0, 26, 54, 1) 0%,
        rgba(34, 88, 138, 1) 88%
      );
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-service-market {
      border: 2px solid #9700ff;
      background: #9700ff;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-social-platfrom {
      border: 2px solid #863cb2;
      background: #863cb2;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-school {
      border: 2px solid white;
      background: linear-gradient(to right, #aa0f1b, #5d060d);
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-doctor-appointment {
      border: 2px solid white;
      background: linear-gradient(to right, #2f64af, #53bfd0);
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-mugenzi {
      border: 2px solid #1f603b;
      background: #1f603b;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-digital-business {
      border: 2px solid white;
      background: linear-gradient(
        90deg,
        rgba(238, 4, 166, 1) 9%,
        rgba(86, 20, 130, 1) 78%
      );
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-hospital-management {
      border: 2px solid white;
      background: linear-gradient(
        90deg,
        rgb(250, 74, 5) 9%,
        rgb(255, 145, 0) 78%
      );
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-grocery {
      border: 2px solid white;
      background: linear-gradient(90deg, #b40681 9%, #e462bd 78%);
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-pharmacy-ordering {
      border: 2px solid white;
      background: linear-gradient(to right, #2f64af, #53bfd0);
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-helpdesk-ticketing {
      border: 2px solid #406cff;
      background: #406cff;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &.btn-transport-management {
      border: 2px solid #984065;
      background: #984065;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      @media #{$sm-layout} {
        padding: 9px 25px;
        font-size: 16px;
      }
    }
    &:hover {
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    }
  }
}
