// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
// @import "../public/assets/css/owl.carousel.min.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.bg_color--8 {
    background: #0d6b86 !important;
}

.bg_color--9 {
    background: #0C2142 !important;
}


.chat_btn {
    display: block;
    position: fixed;
    bottom: 10px;
    z-index: 99;
    border: none;
    background-color: #25D366;
    cursor: pointer;
    padding: 0px 14px;
    border-radius: 32px;
    font-size: 36px;
    box-shadow: 0 0 0 0 #ffc20f;
    margin-left: 10px;
    color: #ffff !important;
}

.chat_btn.popup {
    position: absolute;
    // top: 20px;
    right: 10px;
    margin-right: 20px;
}
strong{
    margin-bottom: 15px !important;
}