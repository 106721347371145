.commom_product_tab_screens {
    margin-top: 2rem;
}

.commom_product_tab_screens .container {
    max-width: 100%;


}

.commom_product_tab_screens .slider-button .row {
    margin: 0 -4px;
}

.commom_product_tab_screens .slider-button .col-12 {
    padding: 0 4px;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six {
    display: flex;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    min-height: 60px;
    padding: 5px 10px;
    align-items: center;
    color: black;
    font-size: 14px;
    margin-bottom: 8px;
    background: transparent;
    /* border: 2px solid; */
    /* border-color: linear-gradient(to right, #2f64af, #53bfd0); */
    /* border-color: #E96E00; */
    line-height: normal;

    @media (max-width:374) {
        max-width: 111px;
    }
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.qysmat {
    border-color: #ccb58b;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.event-expert {
    border-color: #808A6D;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.study-matching {
    border-color: #F05A63;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.study-matching-user {
    border-color: #F07317;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.tukusipan-user {
    border-color: #51A300;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.tukusipan-admin {
    border-color: #CD6629;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.matness-admin {
    border-color: green;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btk {
    border-color: #727272;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.queue-management {
    border-color: #BB00F1;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.queue-management-admin {
    border-color: #586BBC;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.ota-user {
    border-color: #E63D3D;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.mugenzi {
    border-color: #1F603B;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.collection {
    border-color: #000000;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.home-cleaning {
    border-color: #A48A43;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.social-media-admin {
    border-color: #003580;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.school-management-product {
    border-color: #AA0F1B;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.food-delivery-web-product {
    border-color: #E96E00;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-marketplace-product {
    border-color: #9700ff;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.emr-product-app {
    border-color: linear-gradient(to right, #2f64af, #53bfd0);
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.ssg {
    border-color: #3B2064;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.social-media-platform {
    border-color: #DD377C;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.primary-care {
    border-color: #298d9c;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.sesa-pay {
    border-color: #ffd939;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-pro {
    border-color: #012296;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.mommycare {
    border-color: #422400;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.water-donation-admin {
    border-color: #245456;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.mediconnect {
    border-color: linear-gradient(to right, #2f64af, #53bfd0);
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.docfinder {
    border-color: #1296cc;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.zowar {
    border-color: #00722e;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.civipol-admin {
    border-color: #1F5F3C;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.civipol-user {
    border-color: #0096ff;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.umrah {
    border-color: #00aeff;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.taxi-subscription {
    border-color: #F2C326;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.fitness-app {
    border-color: #E64C1A;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.hospital-management-system {
    border-color: #E64C1A;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.faster-checks {
    border-color: #ff5500;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.car-space {
    border-color: #3BDFBD;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.social-sync {
    border-color: #5e5bb6;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-provider {
    border-color: #126075;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.influencer {
    border-color: #DB5E70;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.online-book {
    border-color: #2196f3;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.researcher-personal {
    border-color: #012296;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.digital-business-cards {
    border-color: #960178;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.real-state {
    border-color: #080E41;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.social-media-marketing {
    border-color: #007BFE;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.transport-management-system-product {
    border-color: #984063;
}

.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.helpdesk-product {
    border-color: #406CFF;
}

@media screen and (max-width:1024px) {
    .commom_product_tab_screens .slider-button .col-12 .btn_six {
        min-height: 70px !important;
        font-size: 12px;
    }
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.event-expert.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.event-expert:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.event-expert.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.event-expert:hover {
    background: #808A6D;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.hospital-management-system.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.hospital-management-system:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.hospital-management-system.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.hospital-management-system:hover {
    /* background: #ccb58b; */
    background: linear-gradient(90deg,
            rgb(250, 74, 5) 9%,
            rgb(255, 145, 0) 78%);
    border-color: #ffffff;
    color: #000000
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.online-delivery-system.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.online-delivery-system:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.online-delivery-system.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.online-delivery-system:hover {
    /* background: #ccb58b; */
    background: linear-gradient(90deg,
            #121c84 9%,
            #9793f3 78%);
    border-color: #ffffff;
    color: #fff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.social-sync-system.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.social-sync-system:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.social-sync-system.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.social-sync-system:hover {
    /* background: #ccb58b; */
    background: linear-gradient(90deg,
            #0771b8 9%,
            #0771b8 78%);
    border-color: #ffffff;
    color: #000000
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.qysmat.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.qysmat:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.qysmat.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.qysmat:hover {
    background: #ccb58b;
    border-color: #ffffff;
    color: #000000
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.study-matching.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.study-matching:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.study-matching.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.study-matching:hover {
    background: #F05A63;
    border-color: #ffffff;
    color: #000000
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.study-matching-user.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.study-matching-user:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.study-matching-user.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.study-matching-user:hover {
    background: #F07317;
    border-color: #ffffff;
    color: #000000
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.tukusipan-user.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.tukusipan-user:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.tukusipan-user.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.tukusipan-user:hover {
    background: #51A300;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.tukusipan-admin.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.tukusipan-admin:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.tukusipan-admin.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.tukusipan-admin:hover {
    background: #CD6629;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.matness-admin.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.matness-admin:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.matness-admin.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.matness-admin:hover {
    background: linear-gradient(90deg, rgba(67, 207, 3, 1) 0%, rgba(228, 255, 25, 1) 100%);
    border-color: #ffffff;
    color: #000000;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btk.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btk:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btk.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btk:hover {
    background: #727272;
    border-color: #ffffff;
    color: #FDDC41;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.queue-management.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.queue-management:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.queue-management.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.queue-management:hover {
    background: #BB00F1;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.queue-management-admin.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.queue-management-admin:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.queue-management-admin.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.queue-management-admin:hover {
    background: #586BBC;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.ota-user.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.ota-user:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.ota-user.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.ota-user:hover {
    background: #E63D3D;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.mugenzi.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.mugenzi:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.mugenzi.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.mugenzi:hover {
    background: #1F603B;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.collection.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.collection:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.collection.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.collection:hover {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 22%, rgba(158, 157, 151, 1) 100%);
    border-color: #000000;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.lms.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.lms:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.lms.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.lms:hover {
    background: linear-gradient(90deg, rgba(0, 26, 54, 1) 0%, rgba(34, 88, 138, 1) 88%);
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.home-cleaning.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.home-cleaning:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.home-cleaning.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.home-cleaning:hover {
    background: #A48A43;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.social-media-admin.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.social-media-admin:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.social-media-admin.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.social-media-admin:hover {
    background: #003580;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.school-management-product.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.school-management-product:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.school-management-product.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.school-management-product:hover {
    background: linear-gradient(to right, #aa0f1b, #5d060d);
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.real-state.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.real-state:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.real-state.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.real-state:hover {
    background: #080E41;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.food-delivery-web-product.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.food-delivery-web-product:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.food-delivery-web-product.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.food-delivery-web-product:hover {
    background: #E96E00;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.service-marketplace-product.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.service-marketplace-product:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.service-marketplace-product.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.service-marketplace-product:hover {
    background: #9700ff;
    border-color: #ffffff;
    color: #ffffff;
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.emr-product-app.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.emr-product-app:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.emr-product-app.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.emr-product-app:hover {
    background: linear-gradient(to right, #2f64af, #53bfd0);
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.ssg.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.ssg:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.ssg.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.ssg:hover {
    background: #3B2064;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.social-media-platform.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.social-media-platform:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.social-media-platform.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.social-media-platform:hover {
    background: #DD377C;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.primary-care.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.primary-care:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.primary-care.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.primary-care:hover {
    background: #298d9c;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.sesa-pay.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.sesa-pay:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.sesa-pay.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.sesa-pay:hover {
    background: #ffd939;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.service-pro.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.service-pro:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.service-pro.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.service-pro:hover {
    background: #012296;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.mommycare.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.mommycare:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.mommycare.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.mommycare:hover {
    background: #422400;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.water-donation-admin.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.water-donation-admin:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.water-donation-admin.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.water-donation-admin:hover {
    background: #245456;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.mediconnect.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.mediconnect:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.mediconnect.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.mediconnect:hover {
    background: linear-gradient(to right, #2f64af, #53bfd0);
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.docfinder.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.docfinder:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.docfinder.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.docfinder:hover {
    background: #1296cc;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.zowar.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.zowar:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.zowar.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.zowar:hover {
    background: #00722e;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.civipol-admin.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.civipol-admin:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.civipol-admin.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.civipol-admin:hover {
    background: #1F5F3C;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.civipol-user.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.civipol-user:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.civipol-user.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.civipol-user:hover {
    background: #0096ff;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.umrah.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.umrah:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.umrah.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.umrah:hover {
    background: #00aeff;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.taxi-subscription.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.taxi-subscription:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.taxi-subscription.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.taxi-subscription:hover {
    background: #F2C326;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.fitness-app.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.fitness-app:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.fitness-app.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.fitness-app:hover {
    background: #E64C1A;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.faster-checks.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.faster-checks:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.faster-checks.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.faster-checks:hover {
    background: #ff5500;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.car-space.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.car-space:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.car-space.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.car-space:hover {
    background: #3BDFBD;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.service-provider.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.service-provider:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.service-provider.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.service-provider:hover {
    background: #126075;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.influencer.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.influencer:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.influencer.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.influencer:hover {
    background: #DB5E70;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.online-book.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.online-book:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.online-book.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.online-book:hover {
    background: #2196f3;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.researcher-personal.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.researcher-personal:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.researcher-personal.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.researcher-personal:hover {
    background: #012296;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.digital-business-cards.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.digital-business-cards:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.digital-business-cards.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.digital-business-cards:hover {
    background: linear-gradient(90deg, rgba(238, 4, 166, 1) 9%, rgba(86, 20, 130, 1) 78%);
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.sn.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.sn:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.sn.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.sn:hover {
    background: linear-gradient(to right, #2f64af, #53bfd0);
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.social-media-marketing.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.social-media-marketing:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.social-media-marketing.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.social-media-marketing:hover {
    background: #007BFE;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.transport-management-system-product.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.transport-management-system-product:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.transport-management-system-product.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.transport-management-system-product:hover {
    background: #984065;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.helpdesk-product.active,
.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.helpdesk-product:hover,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.helpdesk-product.active,
.commom_product_tab_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.btn_six.helpdesk-product:hover {
    background: #406CFF;
    border-color: #ffffff;
    color: #ffffff
}

.commom_product_tab_screens.bg-white {
    background: var(--white);
}

.commom_product_tab_screens.bg-white .slider-button .col-12 .btn_six {
    background: #f9f9fc;
    border-color: #f9f9fc;

}

.commom_product_tab_screens .slider-button .col-12 .btn_six span {
    width: 100%;
    text-align: center;
}

.commom_product_tab_screens .owl-dots {
    display: none !important;
}

.commom_product_tab_screens .slider_box_tab {
    position: relative;
    max-width: 802px;
    margin-top: 5rem !important;
    /* margin-left: auto; */
    margin-right: 0;
}

.commom_product_tab_screens .slider_box_tab .frame {
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    width: fit-content;
}

creens .slider_box_tab .slick-slide {
    overflow: visible;
    /* padding: 0px 8px; */
}

.commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
    height: 406px;
}

.commom_product_tab_screens .slider_box_tab .slick-slide.active.center::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: url("../img/app-info/Frame.png"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: none;
}

.commom_product_tab_screens .slider_box_tab .slick-slide.active.center .img_box {
    border-radius: 20px;
    overflow: hidden;
}

.commom_product_tab_screens .slider_box_tab .slick-slide .item .img_box {
    width: 100%;
    height: 100%;
}

.commom_product_tab_screens .slider_box_tab .owl-carousel2 .slick-slide .img_box {
    /* border: 5px solid rgba(0, 0, 0, 0); */
    overflow: hidden;
    transition: all 0.5s;
}

.commom_product_tab_screens .slider_box_tab .owl-carousel3 .slick-slide.active.center .img_box,
.commom_product_tab_screens .slider_box_tab .owl-carousel2 .slick-slide.active.center .img_box {
    border-radius: 12px;
    /* border-color: #000; */
    box-shadow: 12px 8px 17px 12px rgb(0 0 0 / 20%);
    z-index: 999;
}

.commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame {
    top: 34px;
}

.commom_product_tab_screens .slider_box_tab .owl-carousel3 .owl-stage,
.commom_product_tab_screens .slider_box_tab .owl-carousel2 .owl-stage {
    padding: 35px 0;
}


.commom_product_tab_screens .slider_box_tab .slick-slide.active.center .img_box {
    border-radius: 20px;
    overflow: hidden;
}

.commom_product_tab_screens .slider_box_tab .frame {
    top: -11px;
}

.commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
    height: 407px;
}

.commom_product_tab_screens .slider_box_tab .owl-carousel2 .owl-stage {
    padding: 34px 0px;
}

.commom_product_tab_screens .slider_box_tab .owl-carousel2 .owl-stage .item {
    padding: 0 20px;
}


.commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame {
    top: 28px;
}

.commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
    height: 389px;
}

.commom_product_tab_screens .slider_box_tab .frame {
    top: -6px;
}

.commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
    height: 346px;
}

.commom_product_tab_screens .slider_box_tab .owl-carousel2 .slick-slide.active.center .img_box {
    box-shadow: none;
}


@media (max-width: 767px) {
    .commom_product_tab_screens .slider_box_tab {
        max-width: 400px;
        margin: 0 auto;
        max-height: 400px;

    }

    .commom_product_tab_screens .slider_box_tab .owl-carousel .slick-slide img {
        display: block;
        width: 288px;
        height: 222px;
        margin: 0 auto;
        width: auto;
    }

    .commom_product_tab_screens .slider_box_tab .slick-slide.active.center .img_box img {
        border-radius: 20px;
    }


    .commom_product_tab_screens {
        padding-right: 30px !important;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 228px;
        margin-top: -30px;
    }

    .commom_product_tab_screens .slider-button .col-12 {
        max-width: 50%;
    }
}

@media (max-width: 376px) {
    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame {
        top: 33px;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 228px;
        margin-top: -36px;
    }

    .slider_box_tab {
        width: 100%;
        height: 345px;
    }

}

@media (max-width: 374px) {
    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 228px;
        margin-top: -36px;
    }

    #home-page .section-2 .banner_img lottie-player {
        width: 187%;
        right: 603px;
        top: -10px;
    }

    .carousel-item-tabimage {
        height: 220px;
        width: 100%
    }

}

@media (min-width: 768px) {
    .commom_product_tab_screens .slider_box_tab .frame {
        top: 0px;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        /* height: 340px; */
        height: 325px;
        margin-top: -32px;
        width: 490px;
        margin-left: -45px;
    }


    .carousel-item-tabimage {
        height: 317px;
        width: 440px;
        margin-left: 3px;
    }
}

@media (min-width: 992px) {

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 415px;
        width: 595px;
        margin-left: 1px;
    }

    .carousel-item-tabimage {
        height: 406px;
        margin-left: 6px;
        width: 560px;
    }


}

@media (min-width: 1200px) {
    .commom_product_tab_screens .slider_box_tab .frame {
        top: -6px;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 359px;
        width: 460px;
    }


    .carousel-item-tabimage {
        width: 450px;
        height: 350px;
        margin-left: 145px;
    }

    .slider_box_tab .add-margin .frame.coursier-web-frame img {
        margin-right: 5px;
    }
}

@media (min-width: 1250px) {

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 380px;
        margin-left: 12px;
        width: 500px;
    }

    .carousel-item-tabimage {
        width: 498px;
        height: 370px;
        margin-left: 143px;
    }

    .slider_box_tab .add-margin .frame.coursier-web-frame img {
        margin-right: 5px;
    }
}

@media (min-width: 1440px) {
    .commom_product_tab_screens .container {
        max-width: 1280px;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        /* height: 415px;
        margin-top: -31px;
        margin-left: -25px;
        width: 670px; */
        height: 417px;
        margin-top: -33px;
        margin-left: -27px;
        width: 680px;
    }

    .carousel-item-tabimage {
        height: 406px;
        margin-left: 39px;
        width: 640px;
    }

}

@media (min-width: 1920px) {
    .commom_product_tab_screens .container {
        max-width: 1470px;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 410px;
    }

}

@media (min-width: 2500px) {
    .commom_product_tab_screens .container {
        max-width: 1750px;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 565px;
    }

    .carousel-item-tabimage {
        height: 575px;
        /* margin-left: 56px; */
    }

}

@media (min-width: 2880px) {
    .commom_product_tab_screens .container {
        max-width: 1950px;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 580px;
    }
}

@media (min-width: 3584px) {
    .commom_product_tab_screens .container {
        max-width: 2297px;
    }

    .commom_product_tab_screens .slider_box_tab .frame.coursier-web-frame img {
        height: 580px;
    }
}

.app_screens .slider_box .owl-carousel2 .owl-item .img_box {
    /* border: 5px solid rgba(0, 0, 0, 0); */
    overflow: hidden;
    transition: all 0.5s;
}

.app_screens .slider_box .owl-carousel2 .owl-item.active.center .img_box {
    border-radius: 12px;
    /* border-color: #000; */
    box-shadow: 12px 8px 17px 12px rgb(0 0 0 / 20%);
    z-index: 999;
}

.app_screens .slider_box .owl-carousel2 .owl-stage {
    padding: 35px 0;
}

.app_screens .slider_box .owl-carousel2 .owl-stage {
    padding: 34px 0px;
}

.app_screens .slider_box .owl-carousel2 .owl-stage .item {
    padding: 0 20px;
}

.app_screens .slider_box .owl-carousel2 .owl-item.active.center .img_box {
    box-shadow: none;
}

/* .common_product_app_screens {
    background: #f9f9fc;
    padding-left: 180px;
} */

.common_product_app_screens .container {
    /* max-width: 100%; */
    margin: 0 0 0 auto;
}

.common_product_app_screens .slider-button .row {
    margin: 0 -4px;
}

.common_product_app_screens .slider-button .col-12 {
    padding: 0 4px;
}

.common_product_app_screens .slider-button .col-12 .btn_six {
    display: flex;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    min-height: 60px;
    padding: 5px 10px;
    align-items: center;
    color: black;
    font-size: 14px;
    /* font-size: 14px; */
    margin-bottom: 8px;
    background: transparent;
    /* border: 2px solid; */

    /* border-color: blue !important; */
    line-height: normal;

    @media screen and (max-width:374) {
        max-width: 111px;
    }

    ;
}

.common_product_app_screens .slider-button .col-12 .btn_six.event-expert-app {
    border-color: #808A6D !important;
}

.common_product_app_screens .slider-button .col-12 .btn_six.qysmat {
    border-color: #ccb58b !important;
}

.common_product_app_screens .slider-button .col-12 .btn_six.matness-user {
    border-color: green !important;
}

.common_product_app_screens .slider-button .col-12 .btn_six.queue-management {
    border-color: #FF8100 !important;
}

.common_product_app_screens .slider-button .col-12 .btn_six.social-media {
    border-color: #863CB2;
}

.common_product_app_screens .slider-button .col-12 .btn_six.school-management-app-product {
    border-color: #f8981d;
}

.common_product_app_screens .slider-button .col-12 .btn_six.food-delivery-app-product {
    border-color: #4F0940 !important;
}

.common_product_app_screens .slider-button .col-12 .btn_six.digital-business-cards {
    border-color: #960178 !important;
}

.common_product_app_screens .slider-button .col-12 .btn_six.service-marketplace-product {
    border-color: #9700ff;
}

.common_product_app_screens .slider-button .col-12 .btn_six.emr-product-app {
    border-color: linear-gradient(to right, #2f64af, #53bfd0);
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.primary-care {
    border-color: #298d9c;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.grocery {
    border-color: #b40681;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.sesa-pay {
    border-color: #ffd939;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.online-grocery {
    border-color: #83b735;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.service-pro {
    border-color: #012296;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.mommycare {
    border-color: #422400;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.water-donation-app {
    border-color: #33C6CB;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.mediconnect {
    border-color: linear-gradient(to right, #2f64af, #53bfd0);
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.docfinder {
    border-color: #1296cc;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.umrah {
    border-color: #00aeff;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.taxi-subscription {
    border-color: #F2C326;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.fitness-app {
    border-color: #E64C1A;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.service-provider {
    border-color: #126075;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.online-shop {
    border-color: #233e99;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.ndmfc {
    border-color: #27A3CB;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.ndashaka {
    border-color: #EC005F;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.diet-app {
    border-color: #E55A37;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.papeo {
    border-color: red;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.school-portfolio {
    border-color: #FAAE1A;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.choice-making {
    border-color: #2B3A8F;
}

.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.btn_six.malegaon-basket {
    border-color: #013277;
}

@media screen and (max-width:1024px) {
    .common_product_app_screens .slider-button .col-12 .btn_six {
        min-height: 70px !important;
        font-size: 12px;
    }
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.event-expert-app.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.event-expert-app:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.event-expert-app.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.event-expert-app:hover {
    /* background: linear-gradient(to right, #2f64af, #53bfd0); */
    background: #808A6D;
    border-color: #ffffff;
    color: #ffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.qysmat.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.qysmat:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.qysmat.active,
.common_product_app_screens .slider-button .col-12 .btn_six.qysmat:hover {
    /* background: linear-gradient(to right, #2f64af, #53bfd0); */
    background: #ccb58b;
    border-color: #ffffff;
    color: #000000;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.grocery.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.grocery:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.grocery.active,
.common_product_app_screens .slider-button .col-12 .btn_six.grocery:hover {
    /* background: linear-gradient(to right, #2f64af, #53bfd0); */
    background: #b40681;
    border-color: #b40681;
    color: #fff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.parcel.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.parcel:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.parcel.active,
.common_product_app_screens .slider-button .col-12 .btn_six.parcel:hover {
    background: linear-gradient(to right, #121c84, #9793f3);
    /* background: #b40681; */
    border-color: #121c84;
    color: #fff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.digital-business-cards.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.digital-business-cards:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.digital-business-cards.active,
.common_product_app_screens .slider-button .col-12 .btn_six.digital-business-cards:hover {
    /* background: linear-gradient(to right, #2f64af, #53bfd0); */
    background: linear-gradient(90deg, rgba(238, 4, 166, 1) 9%, rgba(86, 20, 130, 1) 78%);
    /* background:redd; */
    border-color: #ffffff !important;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.matness-user.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.matness-user:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.matness-user.active,
.common_product_app_screens .slider-button .col-12 .btn_six.matness-user:hover {
    /* background: linear-gradient(to right, #2f64af, #53bfd0); */
    /* background: #ccb58b; */
    background: linear-gradient(90deg, rgba(67, 207, 3, 1) 0%, rgba(228, 255, 25, 1) 100%);
    border-color: #ffffff;
    color: #000000;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.queue-management.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.queue-management:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.queue-management.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.queue-management:hover {
    background: #FF8100;
    border-color: #ffffff;
    color: #000000;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.social-media.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.social-media:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.social-media.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.social-media:hover {
    background: #863CB2;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.school-management-app-product.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.school-management-app-product:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.school-management-app-product.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.school-management-app-product:hover {
    background: linear-gradient(117deg, rgba(254, 210, 20, 1) 0%, rgba(248, 152, 29, 1) 100%);
    border-color: #ffffff;
    color: #000000;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.food-delivery-app-product.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.food-delivery-app-product:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.food-delivery-app-product.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.food-delivery-app-product:hover {
    background: #4F0940;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.service-marketplace-product.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.service-marketplace-product:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-marketplace-product.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-marketplace-product:hover {
    background: #9700ff;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.emr-product-app.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.emr-product-app:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.emr-product-app.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.emr-product-app:hover {
    background: linear-gradient(to right, #2f64af, #53bfd0);
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.primary-care.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.primary-care:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.primary-care.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.primary-care:hover {
    background: #298d9c;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.sesa-pay.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.sesa-pay:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.sesa-pay.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.sesa-pay:hover {
    background: #ffd939;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.online-grocery.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.online-grocery:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.online-grocery.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.online-grocery:hover {
    background: #83b735;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.service-pro.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.service-pro:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-pro.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-pro:hover {
    background: #012296;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.mommycare.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.mommycare:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.mommycare.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.mommycare:hover {
    background: #422400;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.water-donation-app.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.water-donation-app:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.water-donation-app.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.water-donation-app:hover {
    background: #33C6CB;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.mediconnect.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.mediconnect:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.mediconnect.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.mediconnect:hover {
    background: linear-gradient(to right, #2f64af, #53bfd0);
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.docfinder.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.docfinder:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.docfinder.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.docfinder:hover {
    background: #1296cc;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.umrah.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.umrah:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.umrah.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.umrah:hover {
    background: #00aeff;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.taxi-subscription.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.taxi-subscription:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.taxi-subscription.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.taxi-subscription:hover {
    background: #F2C326;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.fitness-app.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.fitness-app:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.fitness-app.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.fitness-app:hover {
    background: #E64C1A;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.service-provider.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.service-provider:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-provider.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.service-provider:hover {
    background: #126075;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.online-shop.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.online-shop:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.online-shop.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.online-shop:hover {
    background: #233e99;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.ndmfc.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.ndmfc:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.ndmfc.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.ndmfc:hover {
    background: #27A3CB;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.ndashaka.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.ndashaka:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.ndashaka.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.ndashaka:hover {
    background: linear-gradient(0deg, rgba(203, 20, 116, 1) 0%, rgba(0, 150, 253, 1) 100%);
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.diet-app.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.diet-app:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.diet-app.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.diet-app:hover {
    background: #E55A37;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.papeo.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.papeo:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.papeo.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.papeo:hover {
    background: linear-gradient(145deg, rgb(248, 31, 1) 0%, rgb(238, 7, 110) 100%);
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.school-portfolio.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.school-portfolio:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.school-portfolio.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.school-portfolio:hover {
    background: #FAAE1A;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.choice-making.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.choice-making:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.choice-making.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.choice-making:hover {
    background: #2B3A8F;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.malegaon-basket.active,
.common_product_app_screens.bg-white .slider-button .col-12 .btn_six.btn_six.btn_six.malegaon-basket:hover,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.malegaon-basket.active,
.common_product_app_screens .slider-button .col-12 .btn_six.btn_six.btn_six.malegaon-basket:hover {
    background: #013277;
    border-color: #ffffff;
    color: #ffffff;
}

.common_product_app_screens.bg-white {
    background: var(--white);
}

.common_product_app_screens.bg-white .slider-button .col-12 .btn_six {
    background: #f9f9fc;
    border-color: #f9f9fc;

}

.common_product_app_screens .slider-button .col-12 .btn_six span {
    width: 100%;
    text-align: center;
}

.common_product_app_screens .owl-dots {
    display: none !important;
}

.common_product_app_screens .slider_box {
    position: relative;
    max-width: 802px;
    margin-top: 5rem !important;
    margin-left: auto;
    margin-right: 0;
}

.common_product_app_screens .slider_box .mobile_frame {
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    width: fit-content;
}

.common_product_app_screens .slider_box .mobile_frame img {
    height: 420px;
}

.common_product_app_screens .slider_box .slick-slide {
    overflow: visible;
    /* padding: 0px 8px; */
}



.common_product_app_screens .slider_box .slick-slide.active.center::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: url("../img/app-info/Frame.png"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: none;
}

.common_product_app_screens .slider_box .slick-slide.active.center .img_box {
    border-radius: 20px;
    overflow: hidden;
}

.common_product_app_screens .slider_box .slick-slide .item .img_box {
    width: 100%;
    height: 100%;
}

.common_product_app_screens .slider_box .owl-carousel2 .slick-slide .img_box {
    /* border: 5px solid rgba(0, 0, 0, 0); */
    overflow: hidden;
    transition: all 0.5s;
}

.common_product_app_screens .slider_box .owl-carousel3 .slick-slide.active.center .img_box,
.common_product_app_screens .slider_box .owl-carousel2 .slick-slide.active.center .img_box {
    border-radius: 12px;
    /* border-color: #000; */
    box-shadow: 12px 8px 17px 12px rgb(0 0 0 / 20%);
    z-index: 999;
}

.common_product_app_screens .slider_box .mobile_frame.web-frame {
    top: 34px;
}

.common_product_app_screens .slider_box .owl-carousel3 .owl-stage,
.common_product_app_screens .slider_box .owl-carousel2 .owl-stage {
    padding: 35px 0;
}


.common_product_app_screens .slider_box .slick-slide.active.center .img_box {
    border-radius: 20px;
    overflow: hidden;
}

.common_product_app_screens .slider_box .mobile_frame {
    top: -11px;
}

.common_product_app_screens .slider_box .mobile_frame img {
    height: 410px;
}

.common_product_app_screens .slider_box .owl-carousel2 .owl-stage {
    padding: 34px 0px;
}

.common_product_app_screens .slider_box .owl-carousel2 .owl-stage .item {
    padding: 0 20px;
}

.common_product_app_screens .slider_box .mobile_frame img {
    height: 389px;
}

.common_product_app_screens .slider_box .mobile_frame.web-frame {
    top: 28px;
}

.common_product_app_screens .slider_box .mobile_frame {
    top: -6px;
}

.common_product_app_screens .slider_box .mobile_frame img {
    height: 346px;
}

.common_product_app_screens .slider_box .owl-carousel2 .slick-slide.active.center .img_box {
    box-shadow: none;
}


@media (max-width: 767px) {
    .common_product_app_screens .slider_box {
        max-width: 400px;
        margin: 0 auto;
        max-height: 400px;
    }

    .common_product_app_screens .slider_box .mobile_frame img {
        height: 366px;
        width: 181px;
        margin-top: -1px;
    }

    .common_product_app_screens .slider_box .owl-carousel .slick-slide img {
        display: block;
        max-width: 100%;
        max-height: 350px;
        margin: 0 auto;
        width: auto;
    }

    .common_product_app_screens .slider_box .slick-slide.active.center .img_box img {
        border-radius: 20px;
    }


    .common_product_app_screens {
        padding-right: 30px !important;
    }

    .common_product_app_screens .slider-button .col-12 {
        max-width: 50%;
    }
}

@media (max-width: 376px) {
    .common_product_app_screens .slider_box .mobile_frame.web-frame {
        top: 33px;
    }

}

@media (max-width: 374px) {

    #home-page .section-2 .banner_img lottie-player {
        width: 187%;
        right: 603px;
        top: -10px;
    }
}

@media (min-width: 768px) {
    .common_product_app_screens .slider_box .mobile_frame {
        top: 0px;
    }

    .common_product_app_screens .slider_box .mobile_frame img {
        height: 333px;
        width: 161px;
        margin-top: -8px;
        margin-right: -7px;
    }


    .slider_box.add-margin .mobile_frame img {
        margin-right: 306px;
    }

    .carousel-item-image {
        height: 317px;
        margin-left: 6px;
    }

}

@media (min-width: 992px) {
    .common_product_app_screens .slider_box .mobile_frame img {
        height: 393px;
        width: 196px;
    }

    .carousel-item-image {
        height: 377px;
        margin-left: 6px;
    }

    .slider_box.add-margin .mobile_frame img {
        margin-right: 515px;
        margin-left: -5px;
    }
}

@media (min-width: 1200px) {
    .common_product_app_screens .slider_box .mobile_frame {
        top: -6px;
    }

    .common_product_app_screens .slider_box .mobile_frame img {
        height: 508px;
    }


    .slider_box.add-margin .mobile_frame img {
        margin-right: 575px;
        height: 395px;
        margin-top: -3px;
    }
}

@media (min-width: 1250px) {
    .common_product_app_screens .slider_box .mobile_frame img {
        height: 410px;
        width: 200px;
    }

    .slider_box.add-margin .mobile_frame img {
        margin-top: -4px;
        margin-right: 573px;
    }

    .carousel-item-image {
        height: 391px;
        margin-left: 6px;
    }
}

@media (min-width: 1440px) {
    .common_product_app_screens .container {
        max-width: 1280px;
    }

    .common_product_app_screens .slider_box .mobile_frame img {
        height: 445px;
        width: 225px;

    }

    .carousel-item-image {
        height: 424px;
        margin-left: 35px;
        width: 200px;
        /* margin-left: 35px; */
    }

    .slider_box.add-margin .mobile_frame img {
        margin-right: 495px;
        margin-top: -5px;
    }
}

@media (min-width: 1920px) {
    .common_product_app_screens .container {
        max-width: 1470px;
    }

    .common_product_app_screens .slider_box .mobile_frame img {
        height: 460px;
    }

    .slider_box.add-margin .mobile_frame img {
        margin-right: 496px;
    }

    .carousel-item-image {
        height: 440px;
    }
}

@media (min-width: 2500px) {
    .common_product_app_screens .container {
        max-width: 1750px;
    }

    .common_product_app_screens .slider_box .mobile_frame img {
        height: 593px;
    }

    .carousel-item-image {
        height: 575px;
        margin-left: 56px;
    }

    .slider_box.add-margin .mobile_frame img {
        margin-right: 515px;
    }
}

@media (min-width: 2880px) {
    .common_product_app_screens .container {
        max-width: 1950px;
    }

    .common_product_app_screens .slider_box .mobile_frame img {
        height: 430px;
        width: 233px;
    }

    .carousel-item-image {
        height: 410px;
        width: 205px;
    }

    .slider_box.add-margin .mobile_frame img {
        margin-right: 449px;
    }
}

@media (min-width: 3584px) {
    .common_product_app_screens .container {
        max-width: 2297px;
    }

    .common_product_app_screens .slider_box .mobile_frame img {
        height: 431px;
    }

}