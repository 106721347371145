.slide {
    .inner {
        padding-top: 100px;
        @media #{$sm-layout} {
            padding-top: 70px;
        }
        @media #{$large-mobile} {
            padding-top: 30px;
        }
        h1 {
            &.title {
                font-size: 125px;
                font-weight: 900;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
                font-weight: 900;
                line-height: 130px;
                @media #{$laptop-device} {
                    font-size: 100px;
                    line-height: 112px;
                }
                @media #{$lg-layout} {
                    font-size: 90px;
                    line-height: 103px;
                }
                @media #{$md-layout} {
                    font-size: 70px;
                    line-height: 82px;
                    margin-bottom: 0;
                }
                @media #{$sm-layout} {
                    font-size: 50px;
                    line-height: 70px;
                    margin-bottom: 0;
                }
                @media #{$large-mobile} {
                    font-size: 40px;
                    line-height: 50px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &.slide-style-1 {
        .inner {
            padding-top: 100px;
            @media #{$sm-layout} {
                padding-top: 70px;
            }
            @media #{$large-mobile} {
                padding-top: 30px;
            }
            @media #{$md-layout} {
                padding-top: 50px;
            }
            h1 {
                &.title {
                    @media #{$md-layout} {
                        font-size: 70px;
                        line-height: 82px;
                        margin-bottom: 26px;
                    }
                }
            }
        }
    }

    &.slide-style-2 {
        padding-top: 230px;
        padding-bottom: 284px;
        overflow: hidden;
        @media #{$laptop-device} {
            padding-top: 150px;
            padding-bottom: 150px;
        }
        

        &.fullscreen {
            min-height: 950px;
            @media #{$laptop-device} {
                padding-top: 160px;
                padding-bottom: 160px;
                min-height: auto;
            }
            @media #{$lg-layout} {
                min-height: auto;
            }
            @media #{$md-layout} {
                min-height: auto;
            }
            @media #{$sm-layout} {
                min-height: auto;
            }
        }

        &.slider-box-content {
            height: 650px;
            @media #{$lg-layout} {
                height: auto;
            }
            @media #{$md-layout} {
                height: auto;
            }
            @media #{$sm-layout} {
                height: auto;
            }
        }

        @media #{$lg-layout} {
            padding-top: 160px;
            padding-bottom: 170px;
            min-height: auto;
        }
        @media #{$md-layout} {
            padding-top: 150px;
            padding-bottom: 150px;
            min-height: auto;
        }

        @media #{$sm-layout} {
            padding-top: 150px;
            padding-bottom: 150px;
            min-height: auto;
        }
        .inner {
            position: relative;
            z-index: 2;
            @media #{$sm-layout} {
                padding-top: 0;
                text-align: center !important;
            }
            &.text-right {
                @media #{$sm-layout} {
                    text-align: right !important;
                }
            }
            @media #{$md-layout} {
                padding-top: 30px;
            }
            @media #{$lg-layout} {
                padding-top: 30px;
            }
            > span {
                color: #fff;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 4px;
                display: block;
                margin-bottom: 9px;
            }

            h1 {
                &.title {
                    color: #ffffff;
                    font-size: 100px;
                    font-weight: 900;
                    
                    @media #{$lg-layout} {
                        font-size: 72px;
                    }

                    @media #{$md-layout} {
                        font-size: 60px;
                        margin-bottom: 18px;
                    }
                    @media #{$sm-layout} {
                        font-size: 36px;
                        line-height: 64px;
                    }
                }
            }
            h2 {
                &.title {
                    color: #ffffff;
                    font-size: 55px;
                    font-weight: 900;
                    
                    @media #{$lg-layout} {
                        font-size: 72px;
                    }

                    @media #{$md-layout} {
                        font-size: 60px;
                        margin-bottom: 18px;
                    }
                    @media #{$sm-layout} {
                        font-size: 36px;
                        line-height: 62px;
                    }
                }
            }

            p {
                &.description {
                    color: #c6c9d8;
                    font-size: 24px;
                    line-height: 40px;
                    margin: 0;
                    padding: 0 16%;
                    font-weight: 300;
                    @media #{$sm-layout} {
                        padding: 0;
                    }
                    @media #{$md-layout} {
                        padding: 0 10%;
                    }
                }
            }
            .slide-btn {
                margin-top: 50px;
            }
            &.text-left {
                p {
                    padding: 0 37% 0 0;
                    @media #{$sm-layout} {
                        padding: 0;
                    }
                    @media #{$md-layout} {
                        padding: 0 3% 0 0;
                    }
                    @media #{$lg-layout} {
                        padding: 0 20% 0 0;
                    }
                    @media #{$sm-layout} {
                        padding: 0 0 0% 0;
                    }
                }
            }
            &.text-right {
                p {
                    padding: 0 0 0% 46%;
                    @media #{$sm-layout} {
                        padding: 0 0 0% 0;
                    }
                }
            }
        }

        &.without-overlay{
            .inner {
                p {
                    &.description {
                        color: #fff;
                        opacity: 0.7;
                    }
                }
            }
        }

        &.slider-paralax{
            @media #{$laptop-device} {
                height: auto;
                padding-top: 150px;
                padding-bottom: 150px;
            }
        }
    }

    &.slider-style-partner {
        .inner {
            > span {
                color: #1f1f25;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 4px;
                display: block;
                margin-bottom: 20px;
                @media #{$sm-layout} {
                    font-size: 13px;
                    margin-bottom: 10px;
                }
            }
            h1 {
                &.title {
                    color: darkgoldenrod;
                    font-size: 75px;
                    font-weight: 900;
                    font-family: 'Montserrat', sans-serif;
                    line-height: 90px;
                    text-transform: inherit;
                    width: 70%;

                    @media #{$lg-layout} {
                        padding-top: 50px;
                        font-size: 70px;
                    }

                    @media #{$md-layout} {
                        padding-top: 45px;
                        padding-bottom: 30px;
                        font-size: 60px;
                        line-height: 77px;
                        width: 85%;
                    }

                    @media #{$sm-layout} {
                        padding-top: 40px;
                        font-size: 42px;
                        line-height: 57px;
                        width: 100%;
                    }

                    span{
                        color: $theme-color;
                    }
                }
            }
        }

        .inner2 {
            > span {
                color: #fff8af;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 4px;
                display: block;
                margin-bottom: 20px;
                @media #{$sm-layout} {
                    font-size: 13px;
                    margin-bottom: 10px;
                }
            }
            h1 {
                &.title {
                    color: #1f1f25;
                    font-size: 75px;
                    font-weight: 900;
                    font-family: 'Montserrat', sans-serif;
                    line-height: 90px;
                    text-transform: inherit;
                    width: 70%;

                    @media #{$lg-layout} {
                        font-size: 70px;
                    }

                    @media #{$md-layout} {
                        font-size: 60px;
                        line-height: 77px;
                        width: 85%;
                    }

                    @media #{$sm-layout} {
                        font-size: 42px;
                        line-height: 57px;
                        width: 100%;
                    }

                    span{
                        color: $theme-color;
                    }
                }
            }
        }
        
    }
    &.slide-style-4 {
        padding-top: 230px;
        padding-bottom: 284px;
        overflow: hidden;
        @media #{$laptop-device} {
            padding-top: 150px;
            padding-bottom: 150px;
        }
        

        &.fullscreen {
            min-height: 950px;
            @media #{$laptop-device} {
                padding-top: 160px;
                padding-bottom: 160px;
                min-height: auto;
            }
            @media #{$lg-layout} {
                min-height: auto;
            }
            @media #{$md-layout} {
                min-height: auto;
            }
            @media #{$sm-layout} {
                min-height: auto;
            }
        }

        &.slider-box-content {
            height: 650px;
            @media #{$lg-layout} {
                height: auto;
            }
            @media #{$md-layout} {
                height: auto;
            }
            @media #{$sm-layout} {
                height: auto;
            }
        }

        @media #{$lg-layout} {
            padding-top: 160px;
            padding-bottom: 170px;
            min-height: auto;
        }
        @media #{$md-layout} {
            padding-top: 150px;
            padding-bottom: 150px;
            min-height: auto;
        }

        @media #{$sm-layout} {
            padding-top: 150px;
            padding-bottom: 150px;
            min-height: auto;
        }
        .inner {
            position: relative;
            z-index: 2;
            @media #{$sm-layout} {
                padding-top: 0;
                text-align: center !important;
            }
            &.text-right {
                @media #{$sm-layout} {
                    text-align: right !important;
                }
            }
            @media #{$md-layout} {
                padding-top: 30px;
            }
            @media #{$lg-layout} {
                padding-top: 30px;
            }
            > span {
                color: #fff;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 4px;
                display: block;
                margin-bottom: 9px;
            }

            h2 {
                &.title {
                    color: #ffffff;
                    font-size: 100px;
                    font-weight: 900;
                    
                    @media #{$lg-layout} {
                        font-size: 72px;
                    }

                    @media #{$md-layout} {
                        font-size: 60px;
                        margin-bottom: 18px;
                    }
                    @media #{$sm-layout} {
                        font-size: 36px;
                        line-height: 64px;
                    }
                }
            }
            h1 {
                &.title {
                    color: #ffffff;
                    font-size: 55px;
                    font-weight: 700;
                    line-height: 1.4074;
                    font-family: Poppins,sans-serif;
                    text-transform: inherit;
                    
                    @media #{$lg-layout} {
                        font-size: 72px;
                    }

                    @media #{$md-layout} {
                        font-size: 60px;
                        margin-bottom: 18px;
                    }
                    @media #{$sm-layout} {
                        font-size: 36px;
                        line-height: 62px;
                    }
                }
            }

            p {
                &.description {
                    color: #c6c9d8;
                    font-size: 24px;
                    line-height: 40px;
                    margin: 0;
                    padding: 0 16%;
                    font-weight: 300;
                    @media #{$sm-layout} {
                        padding: 0;
                    }
                    @media #{$md-layout} {
                        padding: 0 10%;
                    }
                }
            }
            .slide-btn {
                margin-top: 50px;
            }
            &.text-left {
                p {
                    padding: 0 37% 0 0;
                    @media #{$sm-layout} {
                        padding: 0;
                    }
                    @media #{$md-layout} {
                        padding: 0 3% 0 0;
                    }
                    @media #{$lg-layout} {
                        padding: 0 20% 0 0;
                    }
                    @media #{$sm-layout} {
                        padding: 0 0 0% 0;
                    }
                }
            }
            &.text-right {
                p {
                    padding: 0 0 0% 46%;
                    @media #{$sm-layout} {
                        padding: 0 0 0% 0;
                    }
                }
            }
        }

        &.without-overlay{
            .inner {
                p {
                    &.description {
                        color: #fff;
                        opacity: 0.7;
                    }
                }
            }
        }

        &.slider-paralax{
            @media #{$laptop-device} {
                height: auto;
                padding-top: 150px;
                padding-bottom: 150px;
            }
        }
    }
  
}
.personal-portfolio-slider-partner {
    position: relative;
    @media #{$sm-layout} {
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            // background: #ffffff;
            opacity: 0.7;
        }
    }
    @media #{$md-layout} {
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            // background: #ffffff;
            opacity: 0.7;
        }
    }
    @media #{$lg-layout} {
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            // background: #ffffff;
            opacity: 0.7;
        }
    }

}