.slide-custom {
    &.personal-portfolio-slider ,
    &.designer-portfolio {
        &.slider-style-3 {
            @media #{$sm-layout} {
                padding: 100px 0;
            }
            @media #{$md-layout} {
                padding: 150px 0;
            }
            @media #{$lg-layout} {
                padding: 150px 0;
                min-width: auto;
                height: auto;
            }
            .inner{
                @media #{$sm-layout} {
                    padding-top: 56px;
                }
                @media #{$md-layout} {
                    padding-top: 76px;
                }
                h1{
                    &.title{
                        @media #{$sm-layout} {
                            font-size: 32px;
                            line-height: 49px;
                        }
                        @media #{$md-layout} {
                            font-size: 53px;
                            line-height: 70px;
                        }
                        @media #{$lg-layout} {
                            font-size: 58px;
                            line-height: 75px;
                        }
                        @media #{$laptop-device} {
                            font-size: 54px;
                            line-height: 68px;
                        }
                    }
                }
                h2 {
                    @media #{$laptop-device} {
                        font-size: 54px;
                        line-height: 1.3;
                    }
                    @media #{$sm-layout} {
                        font-size: 32px;
                        line-height: 1.3;
                    }
                    @media #{$md-layout} {
                        font-size: 53px;
                        line-height: 70px;
                    }
                    @media #{$lg-layout} {
                        font-size: 58px;
                        line-height: 75px;
                    }
                }
            }
        }
    }
    &.designer-portfolio{
        height: 700px;

        @media #{$md-layout} {
            height: auto;
        }

        @media #{$sm-layout} {
            height: auto;
        }

        &.slider-style-3 {
            @media #{$md-layout} {
                height: auto;
                padding: 100px 0;
            }
            @media #{$sm-layout} {
                height: auto;
                padding: 100px 0;
            }
            .inner {
                padding: 0;
                h1 {
                    &.title {
                        width: 100%;
                    }
                }
            }
            .designer-thumbnail {
                img {
                    border-radius: 100%;
                    padding: 0;
                    border: 14px solid #fff;
                    width: 394px;
                    height: 394px;
                    box-shadow: 0 20px 40px rgba(0,0,0,0.1), 0 20px 40px rgba(255,255,255,0.15);
                    @media #{$large-mobile} {
                        width: auto;
                        height: auto;
                    }
                    @media #{$lg-layout} {
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
    &.designer-portfolio2{
        height: 550px;

        @media #{$md-layout} {
            height: auto;
        }

        @media #{$sm-layout} {
            height: auto;
        }

        &.slider-style-3 {
            @media #{$md-layout} {
                height: auto;
                padding: 100px 0;
            }
            @media #{$sm-layout} {
                height: auto;
                padding: 100px 0;
            }
            .inner {
                padding: 0;
                h1 {
                    &.title {
                        width: 100%;
                    }
                }
            }
            .designer-thumbnail {
                img {
                    border-radius: 100%;
                    padding: 0;
                    border: 14px solid #fff;
                    width: 394px;
                    height: 394px;
                    box-shadow: 0 20px 40px rgba(0,0,0,0.1), 0 20px 40px rgba(255,255,255,0.15);
                    @media #{$large-mobile} {
                        width: auto;
                        height: auto;
                    }
                    @media #{$lg-layout} {
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
}