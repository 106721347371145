button,
a {
  &.rn-button-style--2 {
    // color: #c6c9d8;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    // border: 2px solid #b1b4c1;
    padding: 15px 40px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    transition: 0.3s;

    @media #{$sm-layout} {
      padding: 9px 25px;
    }

    &.rn-btn-dark {
      border: 2px solid #1f1f25;
      color: #1f1f25;

      &:hover {
        background: #1f1f25;
        color: #fff;
      }
    }

    &.border-opacity-half {
      border-color: rgba(31, 31, 37, 0.1);
    }

    &.rn-btn-small {
      padding: 10px 20px;
    }

    &.btn-primary-color {
      &:hover {
        border: 2px solid $theme-color;
        background: $theme-color;
        color: #ffffff;
      }
    }

    &.btn-solid {
      border: 2px solid $theme-color;
      background: $theme-color;
      color: #ffffff;
    }

    &.btn-coursier {
      background: linear-gradient(to right, #2f64af, #53bfd0);
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #53bfd0;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-sesapay {
      background: #ffd939;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #ffd939 !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-groceries {
      background: #83b735;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #83b735 !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-tourism {
      background: #00aeff;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #00aeff !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }
    &.btn-taxi-subscription {
      background: #f2c326;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #f2c326 !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }
    &.btn-fitness {
      background: #e64c1a;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #e64c1a !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-healthcare {
      background: #1296cc;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #1296cc !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-primary-care {
      background: #298d9c;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #298d9c !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-courier {
      background: #012296;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #012296 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-dietapp {
      background: #e55a37;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #e55a37 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-online-book {
      background: #2196f3;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #2196f3 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-social-media-platform {
      background: #863cb2;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #863cb2 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-umrah-visa {
      background: #00722e;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #00722e !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-fasterchecks {
      background: #ff5500;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #ff5500 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-portrey {
      background: #126075;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #126075 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-online-kirana {
      background: #013277;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #013277 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-event-kirana {
      background: linear-gradient(
        145deg,
        rgb(248, 31, 1) 0%,
        rgb(238, 7, 110) 100%
      );
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #fe679a !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-school-management {
      background: #faae1a;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #faae1a !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-online-shop {
      background: #233e99;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #233e99 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-digital-solution {
      background: #0096ff;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #0096ff !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-common-color {
      background: #f9004d;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #f9004d !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-nursing-color {
      background: #00dddd;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #00dddd !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-docotor-aapointment-color {
      background: #005d93;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #005d93 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-erp-color {
      background: #f55531;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #f55531 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-food-color {
      background: #580300;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #580300 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-hajj {
      background: #1f6421;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #1f6421 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-hide {
      background: #5aadbf;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #5aadbf !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-memories {
      background: #00c0d1;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #00c0d1 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-car-space {
      background: #3bdfbd;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #3bdfbd !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-online-travel-agency {
      background: #f9b433;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #f9b433 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-powerloom {
      background: #acc0db;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #acc0db !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-service-center {
      background: #b71a25;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #b71a25 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-survey-application {
      background: #c33649;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #c33649 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-choice-making {
      background: #2b3a8f;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #2b3a8f !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-influencer {
      background: #db5e70;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #db5e70 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-social {
      background: #774eb3;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #774eb3 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-home-cleaning {
      background: #a48a43;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #a48a43 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-collection {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 22%,
        rgba(158, 157, 151, 1) 100%
      );
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid rgba(158, 157, 151, 1) !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-mugenzi {
      background: #1f603b;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #1f603b !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-lms {
      background: linear-gradient(
        90deg,
        rgba(0, 26, 54, 1) 0%,
        rgba(34, 88, 138, 1) 88%
      );
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid rgba(34, 88, 138, 1) !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-service-market-product {
      background: #9700ff;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #9700ff !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-btk {
      background: #727272;
      color: #fddc41;

      &:hover {
        background: transparent;
        border-color: 2px solid #727272 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-queue {
      background: #586bbc;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #586bbc !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-match-making {
      background: #ccb58b;
      color: #000000;

      &:hover {
        background: transparent;
        border-color: 2px solid #ccb58b !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-ota {
      background: #586bbc;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #586bbc !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-study-matching {
      background: #f07317;
      color: #000000;

      &:hover {
        background: transparent;
        border-color: 2px solid #f07317 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-matness {
      background: linear-gradient(
        90deg,
        rgba(67, 207, 3, 1) 0%,
        rgba(228, 255, 25, 1) 100%
      );
      color: #000000;

      &:hover {
        background: transparent;
        border-color: 2px solid rgba(67, 207, 3, 1) !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-tukusipan {
      background: #cd6629;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #cd6629 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-ssg {
      background: #3b2064;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #3b2064 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-social-media-platform {
      background: #dd377c;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #dd377c !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-mommycare {
      background: #422400;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #422400 !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-ndmfc {
      background: #27a3cb;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #27a3cb !important;
        color: #000000;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }

    &.btn-digital-business-cards {
      background: linear-gradient(
        90deg,
        rgba(238, 4, 166, 1) 9%,
        rgba(86, 20, 130, 1) 78%
      );
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #27a3cb !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-ndashaka {
      background: linear-gradient(
        0deg,
        rgba(203, 20, 116, 1) 0%,
        rgba(0, 150, 253, 1) 100%
      );
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #27a3cb !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-social-platform {
      background: #863cb2;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #863cb2 !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-school-management-product {
      background: linear-gradient(to right, #aa0f1b, #5d060d);
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #863cb2 !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-real-state {
      background: #080e41;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #863cb2 !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-social-media-marketing {
      background: #007bfe;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #007bfe !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-transport-management {
      background: #984065;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #007bfe !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }

    &.btn-helpdesk-ticketing {
      background: #406cff;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #406cff !important;
        color: #000000;
        transform: translateY(-5px);
      }
    }
    &.btn-water-donation {
      background: #245456;
      color: #ffffff;

      &:hover {
        background: transparent;
        border-color: 2px solid #245456 !important;
        color: #245456;
        // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
      }
    }
  }
}
