// Contact Styles 

.contact-form--1 {
    label {
        display: block;
        margin-bottom: 0;
    }

    input,
    textarea {
        display: block;
        width: 100%;
        padding: 0 20px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: all 0.3s ease;
        height: 50px;
        line-height: 46px;
        margin-bottom: 20px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;

        &:focus {
            border-color: $theme-color;
        }
    }

    textarea {
        height: 120px;
    }
    .phoneInput{
        width: 100%;
        height: 47px;
    }

    .react-tel-input .form-control {
        position: relative;
        font-size: 14px;
        letter-spacing: .01rem;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 48px;
        margin-left: 0;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        border-radius: 5px;
        line-height: 25px;
        height: 47px;
        width: 100%;
        outline: none;}
}

.contact-form--2 {
    label {
        display: block;
        margin-bottom: 0;
    }

    input,
    textarea,
    select {
        display: block;
        width: 100%;
        padding: 0 20px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: all 0.3s ease;
        height: 50px;
        line-height: 46px;
        margin-bottom: 20px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;

        .file-input {
            width: 280px !important;

            @media #{$large-mobile} {
                margin-left: -10px !important;
            }
        }

        &:focus {
            border-color: $theme-color;
        }
    }

    input[type="file"] {
        display: block;
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        padding: 0 20px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: #fff;
        color: #1f1f25;
        font-size: 15px;
        line-height: 46px;
        outline: none;
        cursor: pointer;

        @media #{$large-mobile} {
            width: 270px;
        }

        @media #{$lg-layout} {
            width: 420px;
        }

        @media #{$md-layout} {
            width: 445px;
        }
        @media #{$laptop-device}{
          width: 345px;
        }
    }

    // input[type="file"]:hover {
    //     background-color: #e9e9e9;
    // }

    input[type="file"]:focus {
        border-color: $theme-color;
    }

    textarea {
        height: 120px;
    }
}


/*==========================
    Contact Address 
============================*/

.rn-address {
    margin-top: 30px;
    border: 1px solid #ebebeb;
    padding: 40px;
    border-radius: 10px;
    padding-top: 54px;
    background: #fff;
    padding-bottom: 50px;

    .icon {
        border: 1px solid #ebebeb;
        width: 78px;
        height: 78px;
        color: $theme-color;
        line-height: 73px;
        text-align: center;
        border-radius: 100%;
        background: #ffffff;
        font-size: 28px;
        position: absolute;
        top: -13px;
        left: 47px;
        @extend %transition;
    }

    .inner {
        h4 {
            &.title {
                font-size: 18px;
                font-weight: 700;
            }
        }

        p {
            font-size: 18px;
            margin-bottom: 0;
            color: rgba(29, 29, 36, 0.75);

            @media #{$lg-layout} {
                font-size: 16px;
            }

            @media #{$md-layout} {
                font-size: 16px;
            }

            @media #{$sm-layout} {
                font-size: 16px;
            }

            br {

                @media #{$lg-layout} {
                    display: none;
                }

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }

            a {
                color: rgba(29, 29, 36, 0.75);
                @extend %transition;

                &:hover {
                    color: $theme-color;
                }
            }
        }
    }

    &:hover {
        .icon {
            background: $theme-color;
            border-color: $theme-color;
            color: #fff;
        }
    }
}

input,
textarea {
    @extend %transition;
}

.blog-comment-form .inner .rnform-group input:focus,
.blog-comment-form .inner .rnform-group textarea:focus {
    border-color: $theme-color ;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-top: 20px;
}