.product-tab-button {
    display: flex;
    justify-content: center;
    list-style: none;
    background: rgba(255, 26, 80, 0.03);
    min-width: 570px;
    margin: 0 auto;
    border-radius: 6px;
    margin-bottom: 30px;
    margin-top: 35px;
    flex-wrap: wrap;
    padding: 0;
    padding: 0 36px;
    outline: none;

    @media #{$sm-layout} {
        margin-bottom: 18px;
        margin-top: 5px;
        padding: 8px 15px;
    }

    @media #{$md-layout} {
        padding: 0 15px;
    }

    @media #{$sm-layout} {
        min-width: auto;
    }

    .label {
        position: relative;

        &::after {
            position: absolute;
            top: 0;
        }
    }

    li {
        padding: 0 14px;
        color: #101010;
        font-size: 16px;
        cursor: pointer;
        outline: none;

        @media #{$large-mobile} {
            padding: 0 11px;
        }

        span {
            position: relative;
            display: block;
            padding: 20px 0;
            cursor: pointer;

            @media #{$large-mobile} {
                padding: 11px 0;
            }

            &::after {
                position: absolute;
                left: 0;
                background: $theme-color;
                bottom: 0;
                height: 3px;
                content: "";
            }
        }

        &.selected {
            span {
                color: $theme-color;

                &::after {
                    width: 100%;
                }
            }
        }
    }
}